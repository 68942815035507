
				var addMethods = require("../node_modules/workerize-loader/dist/rpc-wrapper.js")
				var methods = ["DEFAULT_OPTS","setAuthHeader","setBufferPoller","setPoller","setPollerForHiroTrendingAndWatchlist","clearPoller"]
				module.exports = function() {
					var w = new Worker(__webpack_public_path__ + "64da6f82f4b931fe3d1c.worker.js", { name: "[fullhash].worker.js" })
					URL.revokeObjectURL(__webpack_public_path__ + "64da6f82f4b931fe3d1c.worker.js");
					addMethods(w, methods)
					
					return w
				}
			